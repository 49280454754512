import { graphql } from 'gatsby'
import React from 'react'

import NftstoriesController from '../screens/nftstories'

const Page = (props) => <NftstoriesController {...props} />

export default Page

export const pageQuery = graphql`
  query ($lang: String) {
    articles: allWpNftStory(
      sort: { fields: dateGmt, order: DESC }
      filter: { locale: { locale: { eq: $lang } } }
    ) {
      edges {
        node {
          id
          title
          slug
          nodeType
          content
          excerpt
          uri
          date(formatString: "MMM DD, YYYY")
          dateGmt
          categories {
            nodes {
              name
              slug
              description
              id
            }
          }
          acf: acfNftStories {
            readingDuration
          }
          featuredImage {
            node {
              id
              altText
              sourceUrl

            }
          }
        }
      }
    }
  }
`
