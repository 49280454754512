import React from 'react'

import TemplateController from '../category-page-template/template-controller'
import View from './nftstories-view'

const NftstoriesController = ({ data, location }) => {
  const viewProps = TemplateController({ data, location, slug: 'nft-stories' })

  return <View {...viewProps} />
}

export default NftstoriesController
