import './nftstories-styles.scss'

import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  AdsSection,
  CardGroupHeader,
  CardList,
  FilterMobile,
  Filters,
  Seo,
  Title,
} from '../../components'

const NftstoriesView = ({
  articles,
  trendingArticles,
  handleFilter,
  selectedCategories,
}) => {
  const { t, i18n } = useTranslation()
  return (
    <div>
      <Seo
        title={t('nft-stories.meta.title')}
        description={t('nft-stories.meta.description')}
        link="https://crypto.com/nft-stories"
        lang={i18n.language}
      />
      <FilterMobile
        type="nft-stories"
        title="topics"
        onClick={handleFilter}
        selectedCategories={selectedCategories}
      />
      <div className="has-mobile-filter">
        <CardGroupHeader
          title="nft-stories"
          data={trendingArticles}
          titleUrl="/nft-stories"
        />
        <div className="article-list-box">
          <Title title={t('nft-stories.titleList')} />
          <Filters
            type="nft-stories"
            title="topics"
            onClick={handleFilter}
            selectedCategories={selectedCategories}
          />
          <CardList data={articles} />
        </div>
        <AdsSection />
      </div>
    </div>
  )
}

export default NftstoriesView
